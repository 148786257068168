import { development } from "./development";
import { production } from "./production";

const paths = {
    stage1: `vaspian-stage1.vaspian.com`,
    stage2: `vaspian-stage2.vaspian.com`,
    dev: `vaspian-dev.vaspian.com`,
    test: `vaspian-test.vaspian.com`,
    local: `vaspian-stage1.vaspian.com`,
    // local: `vaspian-dev.vaspian.com`,
    // local: `vaspian-test.vaspian.com`,
    // local: `vaspian-stage1.vaspian.com`,
    // local: `test6.voifinity.com`
    prod: `one.vaspian.com`
}

const dev = {
    env: 'develop',
    baseURL: `https://${paths.dev}/v2/`,
    domain: `${paths.dev}/app`,
    keyCloak: `https://${paths.dev}/auth/`,
    wssProtocol: `pbx-protocol`,
    download: `https://${paths.dev}/v1/api/download/`,
    multiTenant: true,
    config: development
}
const test = {
    env: 'testing',
    baseURL: `https://${paths.test}/v2/`,
    domain: `${paths.test}/app`,
    keyCloak: `https://${paths.test}/auth/`,
    wssProtocol: `pbx-protocol`,
    download: `https://${paths.test}/v1/api/download/`,
    multiTenant: true,
    config: development
}
const stage1 = {
    env: 'stage1',
    baseURL: `https://${paths.stage1}/v2/`,
    domain: `${paths.stage1}/app`,
    keyCloak: `https://${paths.stage1}/auth/`,
    wssProtocol: `pbx-protocol`,
    download: `https://${paths.stage1}/v1/api/download/`,
    multiTenant: true,
    config: development
}
const stage2 = {
    env: 'stage2',
    baseURL: `https://${paths.stage2}/v2/`,
    domain: `${paths.stage2}/app`,
    keyCloak: `https://${paths.stage2}/auth/`,
    wssProtocol: `pbx-protocol`,
    download: `https://${paths.stage2}/v1/api/download/`,
    multiTenant: true,
    config: development
}
const local = {
    env: 'localhost',
    baseURL: `https://${paths.local}/v2/`,
    domain: `${paths.local}/app`,
    keyCloak: `https://${paths.local}/auth/`,
    wssProtocol: `pbx-protocol`,
    download: `https://${paths.local}/v1/api/download/`,
    multiTenant: true,
    config: development
}
const prod = {
    env: 'production',
    baseURL: `https://${paths.prod}/v2/`,
    domain: `${paths.prod}/app`,
    keyCloak: `https://${paths.prod}/auth/`,
    wssProtocol: `pbx-protocol`,
    download: `https://${paths.prod}/v1/api/download/`,
    multiTenant: true,
    config: production
}


export function getENVData() {
    let text = window.location.hostname;
    if (text.includes(paths.dev)) {
        return dev
    } else if (text.includes(paths.test)) {
        return test
    } else if (text.includes(paths.stage1)) {
        return stage1
    } else if (text.includes(paths.stage2)) {
        return stage2
    }
    else if (text.includes(paths.prod)) {
        return prod
    }
    else {
        return local
    }
}
